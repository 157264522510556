<template>
  <div id="leaving-msg">
    <comment :sign="1"></comment>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import comment from "@/components/comment/comment.vue"

export default defineComponent({
  components: {
    comment
  }
})
</script>

<style lang="scss" scoped>

</style>